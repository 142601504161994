import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@src/store";

interface ExpandTableIndex {
    [key: string]: boolean;
}

interface AreasToShow {
    storageCapacity: boolean;
    quickLinksDebitor: boolean;
    quickLinksCreditor: boolean;
    debitorDocs: boolean;
    creditorDocs: boolean;
    creditorDocsOverdue: boolean;
}

export interface DashboardState {
    expand: {
        default: boolean;
        userSpecific?: ExpandTableIndex;
    };
    areasToShow?: Partial<AreasToShow>;
}

const initialState: DashboardState = {
    areasToShow: {
        creditorDocs: true,
        creditorDocsOverdue: true,
        debitorDocs: true,
        quickLinksCreditor: true,
        quickLinksDebitor: true,
        storageCapacity: true,
    },
    expand: {
        default: true,
    },
};

export const dashboardSlice = createSlice({
    initialState,
    name: "dashboard",
    reducers: {
        toggleAreaToShow: (state, action: PayloadAction<Partial<AreasToShow>>) => {
            state.areasToShow = { ...state.areasToShow, ...action.payload };
        },
        toggleExpandDefault: state => {
            state.expand.userSpecific = undefined;
            state.expand.default = !state.expand.default;
        },
        updateExpandUserSpecific: (state, action: PayloadAction<ExpandTableIndex>) => {
            state.expand.userSpecific = action.payload;
        },
    },
});

export const { toggleAreaToShow, toggleExpandDefault, updateExpandUserSpecific } = dashboardSlice.actions;

export const selectDashboard = (state: RootState) => state.dashboard;

export default dashboardSlice.reducer;
